import { Component, Vue } from 'vue-property-decorator';
import { getComponentProps } from '@/utils/vueUtils';
import { TextImageBlockViewModel } from './TextImageModule.types';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
import DottedLine from '@/assets/icons/dotted-line.svg?inline';
import { ImageWidths } from '@/common/interfaces/SizeBreakpoints';
import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';

import { getResizeImage } from '@/utils/commonUtils';
import {CtaButtonStyle} from "@/common/interfaces/CtaLinkItem";
import { getArticleCta } from '@/components/ArticleText/ArticleText.utils';

const TextImageModuleProps = Vue.extend({
  props: getComponentProps<TextImageBlockViewModel>({
    Title: 'Life aboard',
    Text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    Image: { Url: getResizeImage('hike.jpg'), Alt: 'Hikers' },
    IsLeft: false,
    Cta: { Title: 'Read more', Link: '#' },
    NewsletterSingUpCta: {
      Button: {
        ButtonStyle: CtaButtonStyle.Black,
        Text:"",
        Link:"",
        Title: "",
        Action: "Button",
        Target: ""
      },
      Header: "",
      SecondHeader: ""
    },
  }),
});

@Component({
  components: {
    CtaButton,
    DottedLine,
    ResponsiveImageRatio,
  },
})
export default class TextImageModule extends TextImageModuleProps {
  imageWidths: ImageWidths = {
    def: 463,
    sizes: {
      sm: 636,
      md: 992,
      lg: 793,
      xl: 993,
    },
  };
  get TextData() {
    const { NewsletterSingUpCta, Text: ArticleTextRaw } = this.p
    const ArticleText = ArticleTextRaw && ArticleTextRaw.replace(/.pdf"/g, `.pdf" title="PDF download"`)
    if (!NewsletterSingUpCta) return ArticleText
    const { ButtonStyle, Target, Link, Text, Title } = this.p.NewsletterSingUpCta.Button

    return getArticleCta(
      { ButtonStyle, Target, Link, Text, Title },
      ArticleText,
      this.$attrs.contentLinkId,
    )
  }
}
